"use client";

import * as Sentry from "@sentry/nextjs";
import Link from "next/link";
import { useEffect } from "react";

import { links } from "@/data/links";
import { useLang } from "@/hooks/use-lang";

import { Button } from "ui/core/button";

const Error = ({ error, reset }: { error: Error; reset: () => void }) => {
  const { t, lang } = useLang();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <main className="relative grid h-[calc(100vh-var(--my-navbar-height))] place-content-center place-items-center bg-[#F9FBFC] px-5 transition-all">
      <p className="text-base font-semibold text-indigo-600">Oops!</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        {t.error.somethingWentWrong}
      </h1>
      <p className="mt-6 text-base leading-7 text-gray-600">
        {t.error.serverError}
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <Button onClick={() => reset()}>{t.error.retry}</Button>
        <Link
          href={`/${lang}${links.contact}`}
          className="text-sm font-semibold text-gray-900"
        >
          {t.error.contactSupport} <span aria-hidden="true">&rarr;</span>
        </Link>
      </div>
    </main>
  );
};

export default Error;
